// extracted by mini-css-extract-plugin
export var externalProjectsList = "HomePageHighlights-module--external-projects-list--26503";
export var featuredExternalProject = "HomePageHighlights-module--featured-external-project--a3de7";
export var featuredExternalProjectDescription = "HomePageHighlights-module--featured-external-project-description--dee9b";
export var featuredExternalProjectFooter = "HomePageHighlights-module--featured-external-project-footer--ae2db";
export var featuredExternalProjectFooterLink = "HomePageHighlights-module--featured-external-project-footer-link--75315";
export var featuredExternalProjectIcon = "HomePageHighlights-module--featured-external-project-icon--3a2d0";
export var featuredExternalProjectTitle = "HomePageHighlights-module--featured-external-project-title--a1876";
export var featuredExternalProjects = "HomePageHighlights-module--featured-external-projects--36197";
export var featuredExternalProjectsDescription = "HomePageHighlights-module--featured-external-projects-description--672a6";
export var featuredExternalProjectsHeader = "HomePageHighlights-module--featured-external-projects-header--9ab25";
export var featuredExternalProjectsTitle = "HomePageHighlights-module--featured-external-projects-title--90d19";