// extracted by mini-css-extract-plugin
export var featuredInternalProjectsContainer = "home-page-module--featured-internal-projects-container--50350";
export var featuredInternalProjectsSection = "home-page-module--featured-internal-projects-section--80700";
export var featuredInternalProjectsSectionDescription = "home-page-module--featured-internal-projects-section-description--a4009";
export var featuredInternalProjectsSectionTitle = "home-page-module--featured-internal-projects-section-title--d3323";
export var heroContainer = "home-page-module--hero-container--e131e";
export var heroSectionActive = "home-page-module--hero-section-active--b26b3";
export var homepageHeroBodyCopy = "home-page-module--homepage-hero-body-copy--a0a3f";
export var homepageHeroCopy = "home-page-module--homepage-hero-copy--dcf0b";
export var homepageHeroHeading = "home-page-module--homepage-hero-heading--b7c97";
export var homepageHeroSection = "home-page-module--homepage-hero-section--5a46c";
export var recentArticlesContainer = "home-page-module--recent-articles-container--3007b";
export var recentArticlesSectionDescription = "home-page-module--recent-articles-section-description--a75b4";
export var recentArticlesSectionTitle = "home-page-module--recent-articles-section-title--9e1c8";