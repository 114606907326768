// extracted by mini-css-extract-plugin
export var projectModule = "ProjectModule-module--project-module--fd99b";
export var projectModuleButton = "ProjectModule-module--project-module-button--e73de";
export var projectModuleCopy = "ProjectModule-module--project-module-copy--4ff19";
export var projectModuleDescription = "ProjectModule-module--project-module-description--bebb5";
export var projectModuleFooter = "ProjectModule-module--project-module-footer--33ed4";
export var projectModuleFooterLink = "ProjectModule-module--project-module-footer-link--29c01";
export var projectModuleIcon = "ProjectModule-module--project-module-icon--05320";
export var projectModulePrimaryContent = "ProjectModule-module--project-module-primary-content--107f5";
export var projectModuleTitle = "ProjectModule-module--project-module-title--67864";
export var styleRow = "ProjectModule-module--style-row--b8a00";